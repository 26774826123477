.interface-museum {
  /*cursor: "url('/path/to/ancient-pointer.png'), auto",*/
  height: 100vh;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .trash-icon {
    position: absolute;
    top: 20px;
    left: 20px;
    cursor: pointer;
    width: 48px;
    height: 48px;
  }

  .window {
    position: absolute;
    top: 100px;
    left: 300px;
    cursor: pointer;
  }

  .restart-button {
    position: absolute;
    bottom: 20px;
    left: 20px;
    background-color: #6200ea;
    color: white;
    border: none;
    border-radius: 50%;
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 6px rgba(0,0,0,0.2);
    cursor: pointer;
  }
}
