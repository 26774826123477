.memory-recorder {
  width: calc(100% - 48px);
  margin: 24px;
  display: flex;
  flex-direction: column;
  max-width: 800px;

  h1 {
    font-family: fractul-variable, sans-serif;
    font-variation-settings: "wght" 600;
    margin: 0 0 16px;
    font-size: 3.6em;
    text-align: left;

    @media (width <= 768px) {
      text-align: center;
    }
  }

  h2 {
    font-size: 2.4em;
    text-align: left;

    @media (width <= 768px) {
      text-align: center;
    }
  }

  p {
    font-size: 1.2em;
  }

  ul {
    margin: 0;
    padding: 12px;
  }

  .consent-check {
    margin: 8px 0;
  }

  .email-input {
    margin: 8px 0;
    width: 300px;
  }

  .year-input {
    margin: 8px 0 12px;
    width: 300px;
  }

  /*.accordions {
    max-width: 800px;
  }*/

  .text-input {
    margin: 8px 0;

    textarea, button {
      margin: 8px 0;
    }
  }
  
  

  .recording-controls {
    margin: 20px 0;
  }

  .audio-preview {
    margin-top: 20px;
  }

  audio {
    width: 100%;
    max-width: 400px;
  }
}
