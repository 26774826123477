/* FloatingMenu.css */
.fab {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  background-color: white;
  color: black;
  border: none;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.3s;
}

.fab:hover {
  transform: scale(1.1);
}

.fullscreen-overlay {
  font-size: 2em;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.fullscreen-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav-link {
  font-family: fractul-variable, sans-serif;
  font-variation-settings: "wght" 600;
  color: white;
  font-size: 1.6em;
  text-decoration: none;
  margin: 20px 0;
  transition: color 0.3s;
}

.nav-link:hover {
  color: #ccc;
}

.close-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: transparent;
  color: white;
  border: none;
  font-size: 1em;
  cursor: pointer;
  z-index: 1000;
}

.close-button:hover {
  color: #ccc;
}