.placeholder-container {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.placeholder-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.placeholder-title {
    font-family: "fractul-variable", sans-serif;
    font-variation-settings: "wght" 400;
    font-size: 3rem;
    color: white;
    z-index: 1;
    margin-top: 30%;
}

.image-credit {
    font-size: 0.8rem;
    color: white;
    z-index: 1;
    position: absolute;
    bottom: 0;
    left: 10px;
}

.image-credit a {
    color: white;
    text-decoration: underline;
}

@media(min-width: 768px) {
    .placeholder-title {
        font-size: 6rem;
        margin-top: 15%;
    }
}
