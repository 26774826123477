.exhibits {
  width: calc(100% - 48px);
  margin: 24px;

  h1 {
    font-family: fractul-variable, sans-serif;
    font-variation-settings: "wght" 600;
    margin: 0 0 16px;
    font-size: 3.6em;
    text-align: left;

    @media (width <= 768px) {
      text-align: center;
    }
  }

  h2 {
    font-size: 2.4em;
    text-align: left;

    @media (width <= 768px) {
      text-align: center;
    }
  }

  p {
    font-size: 1.2em;
  }
}
