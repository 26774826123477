/* UI Cards */

.scrollable-wrapper {
  display: flex;
  overflow-x: hidden;
  position: relative;
  height: 100vh;
  width: 100%;
}

.scrollable-container {
  display: flex;
  flex-direction: row;
  overflow: auto hidden;
  position: relative;
  min-height: 100%;
  min-width: 100%;
  scroll-snap-type: x mandatory;

  @media (width <= 768px) {
    flex-direction: column;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
  }
}

.card-container {
  min-width: 100%;
  min-height: 100%;
  transition: transform 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
  scroll-snap-align: start;
}

.ui-card {
  width: calc(100% - 82px);
  height: calc(100% - 72px);
  display: flex;
  flex: 0 0 auto;
  margin: 36px 36px 52px;
  perspective: 1000px;
  box-sizing: border-box;

  @media (width <= 768px) {
    width: 320px;
    height: 450px;
    margin: 24px 24px 48px;
    scroll-snap-align: start;
  }
}

.ui-card-inner {
  display: flex;
  flex-direction: row;
  flex: 0 0 calc(100% - 10px); /* minus 10px border */
  position: relative;
  border: 5px solid #333;
  background-color: #7863A6;
  border-radius: 24px;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  color: #fff;

  .os-screenshot {
    width: 90%;
    height: auto;
    align-self: center;
    margin: 4px 0 12px;
    border: 1px solid #fff;
  }

  .os-type {
    position: absolute;
    top: -4px;
    left: 4px;
    max-width: 20px;
    /*width: 20px;*/
    height: auto;
    margin: 0;
    border: 0;
    z-index: 110;
  }


  .turn-front,
  .turn-back {
    display: none;
  }

  h1, h2, h3 {
    font-family: fractul-variable, sans-serif;
    font-variation-settings: "wght" 600;
    margin: 0 0 8px;
  }

  h1 {
    font-size: 1.4em;
    text-align: center;
  }

  h2 {
    font-size: 1.2em;
    text-align: center;
  }

  p {
    font-size: 0.9em;
    line-height: 1.4em;
    margin: 4px 0;

    overflow-x: hidden;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }

  @media (width <= 768px) {
    flex-direction: column;

    .turn-front,
    .turn-back {
      display: block;
      position: absolute;
      width: auto;
      height: auto;
      font-size: 1.6em;
      margin: 0;
      border: 0;
      z-index: 110;
    }

    .turn-front {
      bottom: 0;
      right: 8px;
    }

    .turn-back {
      transform:scaleX(-1);
      bottom: 0;
      left: 8px;
    }

    h1 {
      font-size: 1.1em;
    }

    p {
      font-size: 0.7em;
      line-height: 1.4em;
      margin: 2px 0;
    }
  }
}

.ui-card.flipped .ui-card-inner {
  transform: rotateY(180deg);
}

.ui-card-front {
  margin: 24px 12px 24px 24px;
}

.ui-card-back {
  margin: 24px 24px 24px 12px;
}

.ui-card-front,
.ui-card-back {
  width: calc(100% - 36px);
  height: calc(100% - 24px);
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  background-color: #7863A6;

  @media (width <= 768px) {
    flex: 0 0 100%;
    position: absolute;
    width: calc(100% - 24px);
    height: calc(100% - 24px);
    margin: 12px;
    backface-visibility: hidden;
  }
}

.ui-cards-start,
.ui-cards-end {
  width: calc(100% - 48px);
  height: calc(100% - 48px);

  h1 {
    font-family: fractul-variable, sans-serif;
    font-variation-settings: "wght" 600;
    margin: 0 0 12px;
    font-size: 3.3em;
    text-align: center;
  }

  p {
    font-size: 1.2em;
  }

  @media (width > 768px) {
    p {
      text-align: center;
    }
  }
}

@media (width <= 768px) {
  .ui-card-back {
    z-index: 120; /* cover os-type image */
    transform: rotateY(180deg);
  }
}

/* scroll buttons: desktop only */
@media (width > 768px) {
  .scroll-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgb(0 0 0 / 50%);
    color: white;
    border: none;
    padding: 12px;
    cursor: pointer;
    z-index: 10;
  }

  .scroll-button.left {
    left: 0;
  }

  .scroll-button.right {
    right: 0;
  }
}
