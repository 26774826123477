header {
  position: sticky;
  top: 0;
  background-color: #333;
  color: white;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu-icon {
  font-size: 1.5em;
  cursor: pointer;
}

nav {
  display: none;
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

nav ul li {
  margin: 10px 0;
}

nav ul li a {
  color: white;
  text-decoration: none;
  font-family: "Fractul", sans-serif;
  font-weight: bold;
}

nav ul li a:hover {
  text-decoration: underline;
}

main {
  /*padding: 20px;*/
}

@media (min-width: 768px) {
  nav {
    display: block;
  }

  nav ul {
    display: flex;
  }

  nav ul li {
    margin: 0 10px;
  }

  .menu-icon {
    display: none;
  }
}
