/* Base theme colors */
:root {
  --system7-bg: #E8E8E8;
  --system7-window: #DDD;
  --system7-border: #000;
  --system7-text: #000;
  --system7-white: #FFF;
  --system7-close: #9a9a9a;
  --system7-title-bg: #cdcdff;
  --system7-title-text-bg: #efefef;
}

/* Chicago font import - you'll need to provide the font files */

/* @font-face {
  font-family: 'Chicago';
  src: url('https://fonts.cdnfonts.com/css/chicagoflf'); format('woff2'),
  url('/fonts/ChicagoFLF.woff') format('woff');
  font-weight: normal;
  font-style: normal;
} */

/* System 7.5 Base Styles */
.system7-container {
  background-image: url("/public/assets/background/system7.png");
  font-family: ChicagoFLF, sans-serif;
  min-height: 100vh;
  padding: 1rem;
}

/* Window Styles */
.system7-window {
  background-color: var(--system7-white);
  border: 2px solid var(--system7-border);
  box-shadow: 2px 2px 0 0 var(--system7-border);
  max-width: 32rem;
  margin: 0 auto;
}

/* Title Bar */
.system7-titlebar {
  height: 17px;
  background-color: var(--system7-title-bg);
}

.system7-titlebar-content {
  height: 15px;
  margin: 1px;
  display: flex;
  align-items: center;
  background-image: url("/public/assets/background/system7_window_top.png");
}

.system7-close-box {
  width: 0.75rem;
  height: 0.75rem;
  background-color: var(--system7-white);
  border: 1px solid var(--system7-border);
  margin-right: 0.25rem;
}

.system7-window-title-container {
  flex: 1;
  text-align: center;
}

.system7-window-title {
  flex: 1;
  color: var(--system7-text);
  text-align: center;
  padding: 0 12px;
  background-color: var(--system7-title-text-bg);
  font-size: 0.78rem;
}

/* Content Area */
.system7-content {
  padding: 1rem;
}

/* Form Elements */
.system7-input {
  width: 90%;
  border: 2px solid var(--system7-border);
  background-color: var(--system7-window);
  padding: 0.25rem;
}

.system7-input:focus {
  outline: none;
}

.system7-form-group {
  margin-top: 1rem;
}

.system7-label {
  display: block;
  color: var(--system7-text);
  margin-bottom: 0.25rem;
}

/* Button */
.system7-button {
  border: 2px solid var(--system7-border);
  background-color: var(--system7-window);
  margin-top: 1rem;
  padding: 0.25rem 1rem;
  min-width: 100px;
  text-align: center;
}

.system7-button:active:not(:disabled) {
  background-color: var(--system7-border);
  color: var(--system7-white);
}

.system7-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Error Message */
.system7-error {
  border: 2px solid var(--system7-border);
  background-color: var(--system7-white);
  padding: 0.5rem;
  margin-bottom: 1rem;
}

/* Loading State */
.system7-loading {
  text-align: center;
  margin-top: 2rem;
}

.system7-loading-box {
  display: inline-block;
  border: 2px solid var(--system7-border);
  background-color: var(--system7-white);
  padding: 0.5rem;
}

/* Back Link */
.system7-back-link {
  display: inline-block;
  margin-bottom: 1rem;
  color: var(--system7-text);
  text-decoration: none;
}

.system7-back-link:hover {
  text-decoration: underline;
}

/* Avatar List Specific Styles */
.system7-avatar-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 1.5rem;
  padding: 1rem;
}

.system7-avatar-window {
  background-color: var(--system7-white);
  border: 2px solid var(--system7-border);
  box-shadow: 2px 2px 0 0 var(--system7-border);
}

@media screen and (max-width: 647px) {
  .system7-qrcode-window {
    display: none;
  }
}

.system7-link-window {
  display: flex;
  flex-direction: column;
  background-color: var(--system7-white);
  border: 2px solid var(--system7-border);
  box-shadow: 2px 2px 0 0 var(--system7-border);
}

@media screen and (max-width: 647px) {
  .system7-link-window {
    display: none;
  }
}

.system7-avatar-image {
  width: 100%;
  height: auto;
  /*border-bottom: 2px solid var(--system7-border);*/
  display: block;
}

.system7-avatar-info {
  display: flex;
  flex: 1;
  padding: 0.5rem;
}

/* Floppy Icon Link */
.system7-floppy-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: var(--system7-text);
  padding: 0.5rem;
  text-align: center;
}

.system7-title-link {
  flex: 1;
  text-decoration: none;
  color: var(--system7-text);
  text-align: center;
}

.system7-floppy-icon {
  width: 32px;
  height: 32px;
  margin-bottom: 0.25rem;
}

.system7-floppy-label {
  font-size: 0.75rem;
  word-break: break-word;
  max-width: 100px;
}

/* Create New Button */
.system7-container-link {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  border: 2px solid var(--system7-border);
  background-color: var(--system7-window);
  padding: 0.25rem 1rem;
  margin-bottom: 1rem;
  text-decoration: none;
  color: var(--system7-text);
}

@media screen and (min-width: 648px) {
  .system7-container-link-mobile {
    display: none;
  }
}

.system7-window-link {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  border: 2px solid var(--system7-border);
  background-color: var(--system7-window);
  padding: 3rem 1rem;
  text-decoration: none;
  color: var(--system7-text);
}

.system7-window-link-text {
  width: 200px;
  text-align: center;
  font-size: 1.8rem;
}

.system7-container-link:active, .system7-window-link:active {
  background-color: var(--system7-border);
  color: var(--system7-white);
}

/* Section Title */
.system7-section-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: var(--system7-text);
}

/* Avatar Details Specific Styles */
.system7-details-container {
  max-width: 640px;
  margin: 0 auto;
  padding: 1rem;
}

.system7-details-window {
  background-color: var(--system7-white);
  border: 2px solid var(--system7-border);
  box-shadow: 2px 2px 0 0 var(--system7-border);
}

.system7-details-content {
  padding: 1rem;
}

.system7-details-image {
  width: 100%;
  height: auto;
  border: 2px solid var(--system7-border);
  margin: 0 0 1rem;
}

.system7-details-description {
  background-color: var(--system7-white);
  border: 2px solid var(--system7-border);
  padding: 1rem;
}

.system7-details-description::-webkit-scrollbar {
  width: 16px;
}

.system7-details-description::-webkit-scrollbar-track {
  background: var(--system7-white);
  border-left: 2px solid var(--system7-border);
}

.system7-details-description::-webkit-scrollbar-thumb {
  background: var(--system7-window);
  border: 2px solid var(--system7-border);
}

.system7-no-image {
  background-color: var(--system7-white);
  border: 2px solid var(--system7-border);
  padding: 2rem;
  text-align: center;
  margin: 1rem 0;
}

/* Back Button (in classic Mac OS style) */
.system7-back-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  border: 2px solid var(--system7-border);
  background-color: var(--system7-window);
  padding: 0.25rem 1rem;
  margin-bottom: 1rem;
  text-decoration: none;
  color: var(--system7-text);
}

.system7-back-button:active {
  background-color: var(--system7-border);
  color: var(--system7-white);
}

/* Retro Share Button Styles */
.system7-share-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.system7-share-button {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  cursor: pointer;
  text-decoration: none;
  color: var(--system7-text);
  background: none;
  border: none;
  font-family: Chicago, sans-serif;
}

.system7-share-button:active .system7-share-icon {
  transform: scale(0.95);
}

.system7-share-icon {
  width: 32px;
  height: 32px;
  transition: transform 0.1s ease;
}

.system7-share-label {
  font-size: 0.75rem;
  text-align: center;
}

/* Share Menu Styles */
.system7-share-menu {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--system7-window);
  border: 2px solid var(--system7-border);
  box-shadow: 4px 4px 0 0 var(--system7-border);
  padding: 1rem;
  z-index: 1000;
}

.system7-share-menu-options {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-top: 1rem;
}

.system7-share-overlay {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 50%);
  z-index: 999;
}
